<template>
  <!-- FOOTER START -->
  <div class="footer">
    <div class="footer-columns-container">
      <div class="footer-group">
        <h1>Acerca de nosotros</h1>
        <ul>
          <li>¿Quiénes somos?</li>
          <li>¿Donde estamos?</li>
          <li> Contáctanos</li>
        </ul>
      </div>
      <div class="footer-group">
        <h1>Comprar</h1>
        <ul>
          <li>Carrito</li>
          <li>Productos más populares</li>
          <li>Ofertas</li>
          <li>Condiciones de envío</li>
          <li>Condiciones de devolución</li>
        </ul>
      </div>
      <div class="footer-group">
        <h1>Categorías</h1>
        <ul>
          <li>Video Juegos</li>
          <li>Fotografía</li>
          <li>TV</li>
          <li>Gafas de realidad aumentada</li>
          <li>Relojes inteligentes</li>
        </ul>
      </div>
      <div class="footer-group">
        <h1>Ayuda y contacto</h1>
        <ul>
          <li>Atención al cliente</li>
          <li>ReTérminos y condiciones</li>
          <li>Ayuda</li>
        </ul>
      </div>
    </div>
    <hr>
    <div class="image-container">
    </div>
  </div>

  <!-- END OF FOOTER -->
</template>

<script>
export default {
  name: "Footer-component",
  props: {
    companyLogo: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.footer {
  width: 100%;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  background-color: var(--footer-bg);
}

.footer-columns-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.footer-group {
  width: 20%;
}

.footer-group h1 {
  font-family: sans-serif;
  color: var(--footer-color);
  margin-top: 20px;
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: bold;
}

.footer-group ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.footer-group ul li {
  font-family: sans-serif;
  color: var(--footer-color);
  font-size: 16px;
  padding: 3px 0px 3px 0px;
  cursor: pointer;
  transition: .2s;
  -webkit-transition: .2s;
  -moz-transition: .2s;
}

.footer-group ul li:hover {
  color: rgb(0, 71, 151);
  transition: .1s;
  -webkit-transition: .1s;
  -moz-transition: .1s;
}

.image-container {
  height: 100px;
  width: 200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-image: var(--companyLogo);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

hr {
  width: 90%;
  margin: 10px auto;
  color: var(--footer-color);
}
</style>
